<script>
/**
 * Widget Component
 */
export default {
  //  props:['valor','pagamento'],
  props: {
    objeto_vend_fat: { type: Object },
    total2: { type: Object },
  },
  mounted() {
    //  console.log('estou  no widget')
    console.log(this.objeto_vend_fat.id);
    this.setar_valores_inicia();
  },
  methods: {
   
    setar_valores_inicia() {
      if (this.objeto_vend_fat.total_pedidos != null) {
        console.log(this.objeto_vend_fat.total_pedidos);
        this.statData[1].value = this.objeto_vend_fat.total_pedidos;
      } else {
        this.statData[1].value = this.total2.total2;
      }
      if (this.objeto_vend_fat.data_pedido != null) {
        this.statData[2].value = this.objeto_vend_fat.data_pedido;
      } else {
        this.statData[2].value = "Sem data";
      }
      //this.statData[3].value = this.objeto_vend_fat.data
      if (this.objeto_vend_fat.pessoa.pes_apelido != null) {
        this.statData[0].value = this.objeto_vend_fat.pessoa.pes_apelido;
      } else {
        this.statData[0].value = "Sem Fornecedor";
      }
      // if(this.objeto_vend_fat.id != null){
      //   this.statData[0].value = this.objeto_vend_fat.id
      // }else {
      //   this.statData[0].value = 'desconnhecido'
      // }
    },
  },
  data() {
    return {
      obj_total:{},
      statData: [
        // {
        //   title: "Pedido",
        //   icon: "ri-stack-line",
        //   value: "",
        //   subvalue: ""
        // },
        {
          title: "Fornecedor",
          icon: "ri-store-2-line",
          value: "Nutricau",
          subvalue: "",
        },
        {
          title: "Valor total dos Pedidos",
          icon: "ri-briefcase-4-line",
          value: "2,00",
          subvalue: "",
        },
        {
          title: "Data do Faturamento",
          icon: "ri-briefcase-4-line",
          value: "10/02/2023",
          subvalue: "",
        },
      ],
    };
  },
  watch: {
    // valor(novoValor) {
    //   this.statData[1].value = novoValor;
    //   this.statData[1].subvalue = novoValor;
    // },
    // pagamento(novoPagamento){
    //   this.statData[2].value = novoPagamento;
    //   this.statData[2].subvalue = novoPagamento;
    // },
    objeto_vend_fat(objeto) {
      this.statData[2].value = objeto.valor;
    },
  },
};
// ola
</script>
<template>
  <div class="row">
    <div class="col-md-4" v-for="(data, index) in statData" :key="index">
      <div class="card">
        <div class="card-body">
          <div class="media">
            <div class="media-body overflow-hidden">
              <p class="text-truncate font-size-14 mb-2">{{ data.title }}</p>
              <h4 class="mb-0">{{ data.value }}</h4>
            </div>
            <div class="text-primary">
              <i :class="`${data.icon} font-size-24`"></i>
            </div>
          </div>
        </div>

        <div class="card-body border-top py-3">
          <div class="text-truncate">
            <span class="badge badge-soft-success font-size-11">
              <i class="mdi mdi-menu-up"></i>
              {{ data.subvalue }}
            </span>
            <span class="text-muted ml-2">-Informação</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- end row -->
</template>
<style scoped>
</style>