<template>
  <b-modal
    v-model="faturando"
    size="xl"
    title="Faturamento"
    hide-footer
    @hidden="faturando_fecha(), back()"
  >
    <div class="row" v-if="recalcular_verifica">
      <div class="col-md-12">
        <widget :objeto_vend_fat="objeto_vend_fat" :total2="obj_total" />
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <label for="">Saldo:</label>
        <div class="card">
          <div class="card-body">
            <div>
              <div class="row">
                <div
                  class="col-md-3"
                  v-for="(saldo, index) in saldos"
                  :key="index"
                >
                  <div class="card">
                    <b-form-checkbox
                      @change="novo_total(saldo, $event, index)"
                      value="1"
                      v-model="saldo.id_venda_iten"
                    >
                      <b-badge
                        data-name="Total"
                        class="field-status"
                        variant="success"
                        >{{ formatterCurrBR(saldo.valor_pag) }}</b-badge
                      >
                      :: pgto :
                      <b-badge
                        data-name="Total"
                        class="field-status"
                        variant="danger"
                        >{{ saldo.id }}</b-badge
                      >
                    </b-form-checkbox>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <label for="">
          <b-badge
            data-name="Comprador"
            class="field-status"
            :variant="select_comprador == null ? 'danger' : 'success'"
            >{{ "*Comprador:" }}</b-badge
          ></label
        >
        <multiselect
          v-model="select_comprador"
          :options="pessoas"
          label="pes_apelido"
        >
        </multiselect>
      </div>
      <div class="col-md-6">
        <label for="">
          <b-badge
            data-name="Comprador"
            class="field-status"
            :variant="select_plano_contas == null ? 'danger' : 'success'"
            >{{ "*Plano de Contas :" }}</b-badge
          ></label
        >
        <multiselect
          v-model="select_plano_contas"
          :options="planos"
          label="descricao"
        >
        </multiselect>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <label for="">Condições de Pagamento:</label>
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-12">
                <b-form-checkbox value="1" id="checkbox5">
                  Com entrada -
                  <Label>Dividir em:</Label>
                  <input
                    v-model="gerar.dividir"
                    type="number"
                    min="1"
                    class="estilo_input mr-4 ml-4"
                  />

                  <Label>com o prazo de: </Label>
                  <input
                    v-model="gerar.prazo_dias"
                    type="number"
                    min="1"
                    class="estilo_input mr-4 ml-4"
                  />
                  {{ "dias" }}
                  <button
                    class="btn btn-secondary ml-4"
                    @click="gerar_parcelas()"
                  >
                    Gerar Parcelas
                  </button>
                </b-form-checkbox>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <!-- responsive -->
        <b-table
          striped
          hover
          :items="fatura_vet"
          id="doc-itens"
          :fields="fields_fatura"
        >
          <template #cell(tp_pagamento)="row">
            {{
              row.item.tp_pagamento === 1
                ? "Dinheiro"
                : row.item.tp_pagamento === 2
                ? "Duplicata"
                : row.item.tp_pagamento === 3
                ? "Cheque"
                : row.item.tp_pagamento === 4
                ? "Cartão de Credito"
                : row.item.tp_pagamento === 5
                ? "Cartão de Debito"
                : row.item.tp_pagamento === 6
                ? "Cupom Devolução"
                : row.item.tp_pagamento === 7
                ? "Transferencia"
                : row.item.tp_pagamento === 8
                ? "Vale Alimentação"
                : row.item.tp_pagamento === 9
                ? "Vale Refeição"
                : row.item.tp_pagamento === 10
                ? "Vale Presente"
                : row.item.tp_pagamento === 11
                ? "Vale Combustivel"
                : row.item.tp_pagamento === 12
                ? "Sem Pagamento"
                : "Outros"
            }}
          </template>
          <!-- <template #cell(valor)="row">
            <span class="text-right">{{
              formatterCurrBR(row.item.valor)
            }}</span>
          </template> -->
          <template #cell(excluir)="row">
            <button @click="Delete_fatura(row.item)" class="btn btn-danger">
              Excluir
            </button>
          </template>
          <template #cell(nnf)="row">
            <b-form-checkbox
              :value="row.index"
              @change="selec_nf(row.item, row.index, 1)"
            >
            </b-form-checkbox>
          </template>
          <template #cell(vencimento)="row">
            <!-- <span>{{row.item.vencimento}}</span> -->
            <input
              v-model="row.item.vencimento"
              type="date-time"
              class="estilo_input1"
              @change="select_vencimento(row.item, row.index)"
            />
          </template>
          <template #cell(esp_pag)="row">
            <multiselect
              v-model="row.item.esp_pag"
              class="larg_mult"
              :options="pagamentos"
              label="tiponome"
              @select="select_tp_pagamento(row.item, row.index)"
            ></multiselect>
          </template>
          <template #cell(conta)="row">
            <multiselect
              v-model="row.item.conta"
              class="larg_mult"
              :options="planos"
              label="descricao"
              @select="select_conta(row.item, row.index)"
            ></multiselect>
            <!-- <input type="text" class="estilo_input1" @click="select_conta(row.item)" > -->
          </template>
          <template #cell(n_doc)="row">
            <input
              v-model="row.item.n_doc"
              type="text"
              class="estilo_input1"
              @input="select_doc(row.item, row.index)"
            />
          </template>
          <template #cell(desc)="row">
            <input
              v-model="row.item.desc"
              type="text"
              class="estilo_input1"
              @input="select_desc(row.item, row.index)"
            />
          </template>
          <template #cell(valor)="row">
            <input
              v-model="row.item.valor"
              @input="formatInput_vlr_fatura(row.index)"
              type="text"
              class="estilo_input1"
            />
          </template>
          <!-- <template #cell(excluir2)="row">
              <multiselect v-model="nada" @select="fat(row.item)" label="tiponome" :options="pagamentos_tp"></multiselect>
            </template> -->
          <!-- <template #cell(tipo_pagamento_id)="row">
              {{ (row.item.tipo_pagamento_id === 1)  ? 'Dinheiro' 
              :  (row.item.tipo_pagamento_id === 2)  ? 'Duplicata' 
              :  (row.item.tipo_pagamento_id === 3)  ? 'Cheque' 
              :  (row.item.tipo_pagamento_id === 4)  ? 'Cartão de Credito' 
              :  (row.item.tipo_pagamento_id === 5)  ? 'Cartão de Debito' 
              :  (row.item.tipo_pagamento_id === 6)  ? 'Cupom Devolução' 
              :  (row.item.tipo_pagamento_id === 7)  ? 'Transferencia' 
              :  (row.item.tipo_pagamento_id === 8)  ? 'Vale Alimentação' 
              :  (row.item.tipo_pagamento_id === 9)  ? 'Vale Refeição' 
              :  (row.item.tipo_pagamento_id === 10) ? 'Vale Presente' 
              :  (row.item.tipo_pagamento_id === 11) ? 'Vale Combustivel' 
              :  (row.item.tipo_pagamento_id === 12) ? 'Sem Pagamento' : 'Outros' 
                }}
            </template> -->
        </b-table>
        <hr />
      </div>
    </div>
    <div class="row d-flex justify-content-right align-items-right">
      <div class="col-md-12 text-right">
        <div class="cerd">
          <div class="card-body">
            <div class="row mb-4">
              <div class="col-md-12">
                <button class="btn btn-secondary ml-4" @click="recalcular()">
                  Recalcular Total
                </button>
              </div>
            </div>
            <div class="row" v-if="total_rezenderiza">
              <div class="col-md-12 mb-2">
                <label for="">Total -></label>
                <input
                  v-model="obj_total.total2"
                  id="margin"
                  class="estilo_input"
                  type="text"
                  @input="
                    formatInputFloat($event, obj_total, 'total2'),
                      recalculo_total2()
                  "
                />
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <b-form-checkbox value="3">
                  Ajustando Valores Automaticamente
                </b-form-checkbox>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <label for="">Observações:</label>
            <textarea
              name=""
              id=""
              cols="30"
              rows="10"
              class="form-control"
            ></textarea>
          </div>
        </div>
      </div>
    </div>
    <div class="row d-flex justify-content-center align-items-center">
      <div class="col-md-12 text-center">
        <!-- back_fatura() -->
        <!-- enviar_parcelas() -->
        <!-- post_parcelas() -->
        <!-- , enviar_parcelas() -->
        <button class="btn btn-secondary" @click.prevent="back_fatura()">
          Faturar
        </button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { http } from "../../../../helpers/easyindustriaapi/config";
import widget from "./widget1.vue";
import Multiselect from "vue-multiselect";
export default {
  props: {
    objeto_faturamento: { type: Object },
    modal_faturar: { type: Boolean },
    pessoas_vet: { type: Array },
    array_plano: { type: Array },
    vet_pag_pessoas: { type: Array },
    pagamentos_vet: { type: Array },
  },
  components: {
    widget,
    Multiselect,
  },
  data() {
    return {
      recalcular_verifica : true,
      total_rezenderiza: false,
      obj_total: {},
      faturando: null,
      tp_contas: [
        {
          id: 1,
          descricao: "Plano 01",
        },
        {
          id: 2,
          descricao: "Plano 02",
        },
      ],
      tp_pagamentos: [],
      saldos: [
        // {
        //   id: 1,
        //   saldo: 200,
        // },
        // {
        //   id: 2,
        //   saldo: 400,
        // },
      ],
      planos: [],
      p_contas: [],
      fatura: {},
      pessoas: [],
      inicial_prePedido: null,
      select_comprador: null,
      fatura_vet: [],
      fatura_parcela: {
        vencimento: "10-02-2023",
      },
      fields_fatura: [
        // {
        //   label: "id_pag",
        //   key: "id_pag",
        //   tdClass: "text-center",
        //   thClass: "text-center",
        // },
        {
          label: "*Vencimento",
          key: "vencimento",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "*Esp Pagamento",
          key: "esp_pag",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "*Conta",
          key: "conta",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "*NF",
          key: "nnf",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "*N doc",
          key: "n_doc",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "*Descrição",
          key: "desc",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "*Valor",
          key: "valor",
          tdClass: "text-center",
          thClass: "text-center",
        },
        // { label: "Criado", key: "data_criacao", tdClass: 'text-right', thClass: 'text-center' },
        {
          label: "Ações",
          key: "excluir",
          tdClass: "text-center",
          thClass: "text-center",
        },
        // { label: "Ações", key: "excluir2", tdClass: 'text-center', thClass: 'text-center' },
      ],
      pagamentos: [],
      gerar: {
        prazo_dias: 30,
        dividir: 1,
      },
      total2: 0,
      select_plano_contas: null,
      select_saldo: null,
      objeto_vend_fat: null,
    };
  },
  created() {
    this.faturando = this.modal_faturar;
    this.objeto_vend_fat = this.objeto_faturamento;
    this.pessoas = this.pessoas_vet;
    //  this.planos =  vet_planos
    //this.array_plano
    this.planos = this.array_plano;
    this.pagamentos = this.pagamentos_vet;
    this.currentEmpresa = JSON.parse(localStorage.getItem("currentEmpresa"));
    this.currentUser = JSON.parse(localStorage.getItem("user"));
    // this.saldos = this.vet_pag_pessoas
  },
  mounted() {
    console.log("Mostrando Objeto Faturamento na tela de Fatuarar Venda");
    console.log(this.objeto_vend_fat);
    this.preenche_saldos();
    this.soma_preco_itens_venda();
  },
  methods: {
    recalculo_total2() {
      
      let valor = this.formatInputFloat(this.obj_total.total2);
      this.obj_total.total2 = valor;
     
    },
    soma_preco_itens_venda() {
      let vet_itens = this.objeto_vend_fat.venda_itens;
      let soma = 0;
      vet_itens.forEach((item) => {
        soma = soma + item.preco;
      });
      console.log("mostrando Soma dos Preços Intes de Venda");
      console.log(soma);

      this.obj_total.total2 = this.formatterCurrBR(soma);
      this.total_rezenderiza = false;
      console.log("mostrando Soma dos Preços Intes de Venda");
      console.log(this.obj_total.total2);
      setTimeout(() => {
        console.log("");
        this.total_rezenderiza = true;
      }, 800);
    },
    preenche_saldos() {
      console.log("estou em preenche saldos");
      let objeto = this.pessoas.find(
        (p) => p.id == this.objeto_vend_fat.cliente_id
      );
      this.saldos = objeto.reg_pag_pessoas;
      console.log("Monstrando oibjeto selecionado pessoa ");
      console.log(this.objeto_vend_fat);
      console.log(this.pessoas);
    },
    select_desc(objeto, index) {
      this.fatura_vet[index].desc = objeto.desc;
      console.log(this.fatura_vet[index].desc);
    },
    select_doc(objeto, index) {
      this.fatura_vet[index].n_doc = objeto.n_doc;
      console.log(this.fatura_vet[index].n_dochis.fatura_vet[index].n_doc);
    },
    selec_nf(objeto, index, valor) {
      console.log(valor);
      this.fatura_vet[index].nnf = valor;
      console.log(this.fatura_vet[index]);
    },

    converte_valor_vet_fatura() {
      let vet = this.fatura_vet;

      vet.forEach((item, index) => {
        let valor = item.valor;

        if (typeof valor === "string") {
          if (valor.includes(".") && valor.includes(",")) {
            valor = parseFloat(valor.replace(".", "").replace(",", "."));
            this.fatura_vet[index].valor = valor;
          } else if (!valor.includes(".") && valor.includes(",")) {
            valor = parseFloat(valor.replace(",", "."));
            this.fatura_vet[index].valor = valor;
          }
        }
      });
    },
    enviar_parcelas() {
      this.converte_valor_vet_fatura();
      let parcela = {};
      parcela.vet = this.fatura_vet;

      let today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, "0"); // Janeiro é 0
      const day = String(today.getDate()).padStart(2, "0");

      const formattedDate = `${year}-${month}-${day}`;
      // this.conta_a_pagar();
      this.fatura_vet.forEach((item) => {
        //console.log(item)
        // this.post_parcelas(item);
        console.log(item);
        let conta_receber = {
          historico_cod: 1,
          cliente_nome: this.objeto_vend_fat.pessoa.pes_apelido,
          cliente_cod: this.objeto_vend_fat.pessoa.id,
          tipo_pagamento: item.tp_pagamento.id,
          num_documento: item.n_doc,
          valor: item.valor,
          qtd_parcelas: this.fatura_vet.length,

          emissao: formattedDate,
          parcela: item.vencimento,
          fatura: formattedDate,
          cod_cupom: 0,
          obs: item.desc,
          empresa_id: this.objeto_vend_fat.empresa_id,
          user_id: this.currentUser.id,
          debito: 1,
          credito: 1,
          venda_id: this.objeto_vend_fat.id,
        };
        this.recebe_parcela_venda({ ...conta_receber });
      });
    },
    recebe_parcela_venda(objeto) {
      this.post_conta_recebe(objeto);
    },

    async post_conta_recebe(conta) {
      console.log("Chamdno contas a receber");
      console.log(conta);
      // let conta = {
      //   vencimento: "2023-02-02",
      //   tp_pagamento: 1,
      //   p_conta: 1,
      //   df: 1,
      //   n_doc: "21",
      //   desc: "ola",
      //   valor: 1,
      //   pre_pedidos_id: 1,
      // };

      //vet = this.fatura_vet

      // let id_pre_pedido = this.inicial_prePedido.id;
      try {
        // let response = await http.get(
        //   "/fornecedor?empresa_id=" +
        //     this.currentEmpresa.id +
        //     "&fornecedor_id=" +
        //     -2
        // );
        let response = await http.post(
          "/receber?empresa_id=" + this.currentEmpresa.id,
          conta
        );
        if (response) {
          // this.offLoader();
          if (response.status === 200) {
            // console.log(response.data);
            //this.getData();
            //this.abri_menu_modal()
            this.inserindo = false;
            this.makeToast("success", "Registro incluído de contas a pagar");
          }
        }
      } catch (error) {
        //this.offLoader();
        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast("danger", "Destino URL não encontrado!");
          } else {
            this.makeToast("danger", error);
          }
        }
      }
    },
    conta_a_pagar() {
      let vet = this.fatura_vet;
      console.log("mostando o vetor de constas a pagar que sãoa as parcelas");
      console.log(vet);
      // this.inicial_prePedido.data
      console.log("mostrando a data do emissão od pre_pedido");
      console.log(this.inicial_prePedido.data);
      vet.forEach((element) => {
        let conta = {
          historico_cod: 1,
          cliente_nome: this.inicial_prePedido.fornecedor.pes_apelido,
          cliente_cod: this.inicial_prePedido.fornecedor.id,
          tipo_pagamento: element.esp_pag.id,
          num_doc: element.n_doc,
          valor: element.valor,
          qtd_parcelas: this.fatura_vet.length,
          emissao: this.inicial_prePedido.data,
          data_parcela: element.vencimento,
          data_fatura: "2023-02-02",
          cod_cupom: 1,
          empresa_id: this.currentEmpresa.id,
          user_id: this.currentUser.id,
          debito: 1,
          credito: 1,
          obs: "ola",
        };
        //conta.cliente_nome = this.inicial_prePedido.pes_apelido
        // conta.tipo_pagamento = element.esp_pag
        console.log(element);
        //console.log(element)
        this.post_conta({ ...conta });
      });
    },
    async post_conta(conta) {
      // let conta = {
      //   vencimento: "2023-02-02",
      //   tp_pagamento: 1,
      //   p_conta: 1,
      //   df: 1,
      //   n_doc: "21",
      //   desc: "ola",
      //   valor: 1,
      //   pre_pedidos_id: 1,
      // };

      //vet = this.fatura_vet

      let id_pre_pedido = this.inicial_prePedido.id;
      try {
        // let response = await http.get(
        //   "/fornecedor?empresa_id=" +
        //     this.currentEmpresa.id +
        //     "&fornecedor_id=" +
        //     -2
        // );
        let response = await http.post(
          "/pagas?empresa_id=" +
            this.currentEmpresa.id +
            "&pre_pedido_id=" +
            id_pre_pedido,
          conta
        );
        if (response) {
          // this.offLoader();
          if (response.status === 200) {
            // console.log(response.data);
            //this.getData();
            //this.abri_menu_modal()
            this.inserindo = false;
            this.makeToast("success", "Registro incluído de contas a pagar");
          }
        }
      } catch (error) {
        //this.offLoader();
        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast("danger", "Destino URL não encontrado!");
          } else {
            this.makeToast("danger", error);
          }
        }
      }
    },
    async post_parcelas(parcela) {
      // let parcela = {
      //   vencimento: "2023-02-02",
      //   tp_pagamento: 1,
      //   p_conta: 1,
      //   df: 1,
      //   n_doc: "21",
      //   desc: "ola",
      //   valor: 1,
      //   pre_pedidos_id: 1,
      // };

      //vet = this.fatura_vet

      let id_pre_pedido = this.inicial_prePedido.id;
      try {
        // let response = await http.get(
        //   "/fornecedor?empresa_id=" +
        //     this.currentEmpresa.id +
        //     "&fornecedor_id=" +
        //     -2
        // );
        let response = await http.post(
          "/parcela?empresa_id=" +
            this.currentEmpresa.id +
            "&pre_pedido_id=" +
            id_pre_pedido,
          parcela
        );
        if (response) {
          // this.offLoader();
          if (response.status === 200) {
            // console.log(response.data);
            //this.getData();
            //this.abri_menu_modal()
            this.inserindo = false;
            // this.makeToast("success", "Registro incluído");
          }
        }
      } catch (error) {
        //this.offLoader();
        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast("danger", "Destino URL não encontrado!");
          } else {
            this.makeToast("danger", error);
          }
        }
      }
    },
    total_pedidos_func() {
      if (
        this.inicial_obj1.total_pedidos != null ||
        this.inicial_obj1.total_pedidos != undefined ||
        this.inicial_obj1.total_pedidos != ""
      ) {
        this.total2 = this.formatterCurrBR(this.inicial_obj1.total_pedidos);
      }
    },
    formatInput_vlr_fatura(index) {
      let numericValue = this.fatura_vet[index].valor.replace(/\D/g, "");
      // Formate o valor numérico para a moeda desejada (por exemplo, BRL)
      this.fatura_vet[index].valor = parseFloat(numericValue / 100)
        .toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        })
        .replace("R$", " ");

        this.obj_total.total2 = this.fatura_vet[index].valor
    },
    recalcular() {
      this.soma_preco_itens_venda();
      // let soma = 0;
      // this.fatura_vet.forEach((item) => {
      //   let valor = item.valor;
      //   if (typeof valor === "string") {
      //     if (valor.includes(".") && valor.includes(",")) {
      //       valor = parseFloat(valor.replace(".", "").replace(",", "."));
      //     } else if (valor.includes(",") && !valor.includes(".")) {
      //       valor = parseFloat(valor.replace(",", "."));
      //     }
      //   }
      //   soma = soma + valor;
      //   this.total2 = this.formatterCurrBR(soma);
      // });
    },
    async get_pagamentos_fornecedores() {
      try {
        let response = await http.get(
          "/regPag?empresa_id=" + this.currentEmpresa.id
        );
        if (response.status === 200) {
          this.saldos = response.data;
          const resultados = this.saldos.filter(
            (item) => item.pessoa_id == this.inicial_prePedido.fornecedor_id
          );
          this.saldos = [];
          this.saldos = resultados;
          // console.log(this.pessoas)
          setTimeout(() => {
            //this.offLoader();
          }, 2000); // tempo para carregar a tela;
        } else {
          //this.p_contas = []; // zera o array;
          this.makeToast("danger", "Erro ao carregar lista");
          //this.offLoader();
        }
      } catch (error) {
        //this.offLoader();
        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast("danger", "Destino URL não encontrado!");
          } else {
            this.makeToast("danger", error.response);
          }
        }
      }
    },
    // usado para fecahr a fatura; tem outro função para isso também
    novo_total(objeto, event, index) {
      // this.inicial_prePedido.itensprepedido[
      //   this.inicial_prePedido.itensprepedido.length - 1
      // ].total

      console.log(index);
      console.log(objeto);
      console.log(event);
      let total = this.new_convert_srting_float(this.obj_total.total2);
      if (event) {
        total = total - objeto.valor_pag;
      } else {
        total = total + objeto.valor_pag;
      }
      this.total_rezenderiza = false;
      this.obj_total.total2 = this.formatterCurrBR(total);
      setTimeout(() => {
        console.log("");
        this.total_rezenderiza = true;
      }, 800);
    },
    back() {
      this.$emit("back");
    },
    convert_total() {
      let sub_total = this.obj_total.total2;
      //this.ini_obj.total_pedidos

      if (typeof sub_total === "string") {
        if (sub_total.includes(".") && sub_total.includes(",")) {
          sub_total = parseFloat(sub_total.replace(".", "").replace(",", "."));
          this.inicial_prePedido.total_pedidos = sub_total;
        } else if (sub_total.includes(",") && !sub_total.includes(".")) {
          sub_total = parseFloat(sub_total.replace(",", "."));
          this.inicial_prePedido.total_pedidos = sub_total;
        }
      } else {
        this.inicial_prePedido.total_pedidos = sub_total;
      }
    },

    back_fatura() {
      //this.post_parcelas();

      //this.converte_data()
      if (this.fatura_vet.length == 0) {
        this.makeToast("danger", "Adicione uma Parcela");
      } else {
        this.makeToast("success", "Faturado");
        this.objeto_vend_fat.faturado = 1;
        this.enviar_parcelas();
        console.log(this.objeto_vend_fat);
        console.log(this.fatura_vet);
        setTimeout(() => {
           this.$emit('fatura_venda_update',this.objeto_vend_fat)
        }, 500);

        // 'historico_cod'      => 'required',
        //         'cliente_nome'       => 'required',
        //         'cliente_cod'        => 'required',
        //         'tipo_pagamento'     => 'required|int',
        //         'num_documento'      => 'required|int',
        //         'valor'              => 'required',
        //         'qtd_parcelas'       => 'required|int',
        //         'emissao'            => 'required',
        //         'parcela'            => 'required',
        //         'fatura'             => 'required',
        //         'cod_cupom'          => 'required',
        //         'obs'                => 'required',
        //         'empresa_id'         => 'required|int',
        //         'user_id'            => 'required|int',
        //         'debito'             => 'required|int',
        //         'credito'            => 'required|int',
      }
      //   }else{
      //
      //   if (this.inicial_prePedido.faturado == 0) {
      //     this.inicial_prePedido.faturado = 1;
      //     // this.convert_total();
      //     this.makeToast("success", "Faturado");
      //     setTimeout(() => {
      //       this.$emit("faturando", this.inicial_prePedido);
      //     }, 500);
      //   } else {
      //     this.makeToast("info", "Compra Já foi Faturada");
      //   }
      // }
    },
    select_conta(objeto, index) {
      this.fatura_vet[index].conta = objeto.conta;
      this.fatura_vet[index].conta_id = objeto.conta.id;
    },
    Delete_fatura() {
      this.fatura_vet.pop();
    },
    converte_data() {
      console.log("estou em data");
      this.fatura_vet.forEach((e) => {
        console.log(e.vencimento);
      });
    },
    gerar_parcelas() {
      this.recalcular_verifica = false
      setTimeout(() => {
        console.log("");
        this.recalcular_verifica = true
      }, 800);
      //let parcela = { ...this.fatura_parcela }

      //this.total_pedidos
      //inicial_prePedido.total_pedidos
      // let total_pedidos = this.formatterCurrBR(
      //   this.inicial_prePedido.itensprepedido[
      //     this.inicial_prePedido.itensprepedido.length - 1
      //   ].total
      // );
      let total_pedidos = this.new_convert_srting_float(this.obj_total.total2);
      if (total_pedidos != null) {
        const total = total_pedidos;
        const resultado = this.formatterCurrBR(total / this.gerar.dividir);
        // esvazia o vetor toda veze que chammo a função
        this.fatura_vet = [];
        for (let i = 1; i <= this.gerar.dividir; i++) {
          const parcela1 = new Date();
          parcela1.setDate(parcela1.getDate() + 30 * i);
          const ano = parcela1.getFullYear();
          const mes = parcela1.getMonth() + 1; // Lembrando que os meses são zero-based
          const dia = parcela1.getDate();
          const dataFormatada = `${ano}-${mes.toString().padStart(2, "0")}-${dia
            .toString()
            .padStart(2, "0")}`;
          this.fatura_vet.push({
            vencimento: dataFormatada,
            valor: resultado,
            venda_id: this.objeto_vend_fat.id,
          });
        }
      } else {
        this.makeToast("danger", "inserir os pedidos na tela de estabelcer");
      }
    },
    makeToast(variant = null, message = "Error generic") {
      this.counter++;
      this.$bvToast.toast(message, {
        title: `Notificação`,
        toaster: "b-toaster-bottom-right",
        variant: variant,
        solid: true,
        appendToast: true,
      });
    },
    select_tp_pagamento(objeto, index) {
      this.fatura_vet[index].tp_pagamento = objeto.esp_pag;
      this.fatura_vet[index].tp_pagamento_id = objeto.esp_pag.id;

      console.log(this.fatura_vet[index].esp_pag);
    },
    // para selecionar o vencimento da tabela dinamica
    select_vencimento(objeto, index) {
      this.fatura_vet[index].vencimento = objeto.vencimento;
      console.log(this.fatura_vet[index].vencimento);
    },
    dlt_parcelas() {},
    faturando_fecha() {
      this.$emit("fecha_modal_fatura");
    },

    async get_pagamentos() {
      try {
        let response = await http.get(
          "/tipopagamento?empresa_id=" + this.currentEmpresa.id
        );
        if (response.status === 200) {
          this.pagamentos = response.data;
          // console.log(this.p_contas)
          setTimeout(() => {
            //this.offLoader();
          }, 2000); // tempo para carregar a tela;
        } else {
          this.pagamentos = []; // zera o array;
          this.makeToast("danger", "Erro ao carregar lista");
          //this.offLoader();
        }
      } catch (error) {
        //this.offLoader();
        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast("danger", "Destino URL não encontrado!");
          } else {
            this.makeToast("danger", error.response);
          }
        }
      }
    },
    async get_pesssoas() {
      try {
        let response = await http.get(
          "/pessoa?empresa_id=" + this.currentEmpresa.id
        );
        if (response.status === 200) {
          this.pessoas = response.data;
          // console.log(this.pessoas)
          setTimeout(() => {
            //this.offLoader();
          }, 2000); // tempo para carregar a tela;
        } else {
          //this.p_contas = []; // zera o array;
          this.makeToast("danger", "Erro ao carregar lista");
          //this.offLoader();
        }
      } catch (error) {
        //this.offLoader();
        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast("danger", "Destino URL não encontrado!");
          } else {
            this.makeToast("danger", error.response);
          }
        }
      }
    },
  },
};
</script>

<style scoped>
.largura {
  width: 130px;
}
.larg_mult {
  width: 130px;
}
.estilo_input {
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.estilo_input1 {
  width: 130px;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
</style>