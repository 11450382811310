<script>
import { indicadorEstoque } from "@/components/defaults/indicadorestoque";
// import Multiselect from "vue-multiselect";
import { http } from "@/helpers/easyindustriaapi/config";

export default {
  components: {
    // Multiselect,
  },
  props: {
    currentEmpresa: { type: Object },
    oldLocalEstoque: { type: Object },
    pessoas: { type: Array },
    produtos: { type: Array },
    tab_precos: { type: Array },
  },
  data() {
    return {
      agrupado_produto_table:false,
      select_empresa: null,
      empresas: [
        {
          id: null,
          nome: null,
      },
      ],
      venda: {},
      data_entrega: null,
      data_pedido: null,
      vet_sit: [
        {
          id: 1,
          nome: "Pedido",
        },
        {
          id: 2,
          nome: "Confirmada",
        },
        {
          id: 3,
          nome: "Carregada",
        },
        {
          id: 4,
          nome: "Entregue",
        },
      ],
      select_sit: null,
      select_tipo_2: null,
      vet_tipos: [
        {
          id: 1,
          nome: "venda",
        },
        {
          id: 2,
          nome: "Orçamento",
        },
        {
          id: 3,
          nome: "Bonificação",
        },
        {
          id: 4,
          nome: "Somento Financeiro",
        },
        {
          id: 5,
          nome: "Venda Direta para Distribuidores",
        },
        {
          id: 6,
          nome: "Remessa para Armazem geral",
        },
      ],
      vet_tipos_unidades: [
        {
          id: 1,
          nome: "Venda/Impressão",
        },
        {
          id: 2,
          nome: "Carregamento",
        },
        {
          id: 3,
          nome: "Principal",
        },
      ],
      select_vend: null,
      select_uni_med: null,
      select_tab_preco: null,
      vet_tab_precos: [],
      index_edit: null,
      editando_table: true,
      select_obj_agrup: null,
      vet_guarda_agrupado: [],
      total_valor: 0,
      outro_valor: {
        frete: 0,
        desconto: 0,
        adicional: 0,
      },
      sub_tot: "Total",
      select_simbol: 1,
      simbolos: [
        {
          id: 1,
          nome: "+",
        },
        {
          id: 2,
          nome: "-",
        },
      ],
      select_outros: null,
      abr_outros: false,
      obj_modal_prod: {},
      show_modal: false,
      produto_obj_selecionado: null,
      items_table_confirmadas: [],
      select_produtos: null,
      vet_produtos: [],
      hidden_button: true,
      abr_table: false,
      items: [],
      select_cliente: null,
      pessoas_vet: [],
      localEstoque: {
        id: -1,
        descricao: null,
        status: true,
        empresa_id: null,
        ind_est: 0,
      },
      listIndicadores: [],
      loader: {
        get: false,
      },
      orientacoes: [
        {
          id: 1,
          nome: "Entrdas e Saídas",
        },
        {
          id: 2,
          nome: "Só entradas",
        },
        {
          id: 3,
          nome: "Só Saídas",
        },
      ],
      tipos: [
        {
          id: 1,
          nome: "Analitica",
        },
        {
          id: 2,
          nome: "Sintetica",
        },
      ],
      select_tipo: null,
      select_orientacao: null,
      planos_contas: [
        {
          id: 1,
          nome: "plano 01",
        },
        {
          id: 2,
          nome: "plano 02",
        },
      ],
      show_multiselect: false,
      select_plano_conta: null,
      plano: {
        woner_id: null,
        descricao: null,
        natureza: null,
        codigo_reduzido: null,
        classificacao: null,
      },
    };
  },
  created() {
    this.pessoas_vet = this.pessoas;
    this.vet_produtos = this.produtos;
    this.vet_tab_precos = this.tab_precos;
    // this.currentUser = JSON.parse(localStorage.getItem("user"));
    // this.currentEmpresa = JSON.parse(localStorage.getItem("currentEmpresa"));
    this.plano = this.oldLocalEstoque;
    this.venda = this.oldLocalEstoque;
    this.items_table_confirmadas = this.oldLocalEstoque.venda_itens

  },
  mounted() {
    this.$emit("newTitle", "Alterar Plano de Conta");
    this.localEstoque = this.oldLocalEstoque;
    this.listIndicadores = indicadorEstoque;
    console.log("Monstrando a emprsa Selecioando no Edit")
    console.log(this.venda.empresa_id)
    // this.empresas[0].id = this.venda.empresa_id
    // this.empresas[0].nome = this.currentEmpresa.apelido
    
  },
  methods: {
    seleciona_empresa(event) {
      console.log(event);
    },
    selectiona_opcoes(event) {
      console.log(event);
    },
    seleciona_tipo(event) {
      console.log(event);
    },
    seleciona_sit(event) {
      console.log(event);
    },
    seleciona_vendedor(event) {
      console.log(event);
    },
    seleciona_uni_med(event) {
      console.log(event);
    },
    seleciona_tab_preco(event) {
      console.log(event);
    },
    async doDelete(plano) {
      // this.onLoader();
      // plano.empresa_id = this.currentEmpresa.id;
      let response = await http
        .delete(
          "/vendaiten/" + plano.id + "?empresa_id=" + this.currentEmpresa.id,
          plano
        )
        .catch((error) => {
          // this.offLoader();
          this.makeToast("danger", error.response);
        });

      if (response.status === 200) {
        // this.getData();
        // this.back();
        this.makeToast("warning", "Registro excluído");
      }
    },
    excluir_table(item, index) {
      console.log(item);
      console.log(index);
      console.log("Estou no exluir da tela de edite")
      if (this.items_table_confirmadas.length > 0) {
        this.items_table_confirmadas.splice(index, 1);
        this.doDelete(item)
      }
    },
    calculo_edit_table(item, index) {
      console.log(item);
      console.log(index);
      let quantidade = this.new_convert_srting_float(item.quantidade);
      let valor = this.new_convert_srting_float(item.valor);
      let preco = 0;
      preco = quantidade * valor;
      console.log("preço");
      console.log(preco);
      this.items_table_confirmadas[index].preco = this.formatterCurrBR(preco);
    },
    edit_table(item, index) {
      this.editando_table = !this.editando_table;
      this.index_edit = index;
      console.log(item);
      console.log(index);
    },
    update_table_confirmados(item,index){
      this.editando_table = !this.editando_table;
      this.index_edit = index;
      console.log("Estou no Update")
      let preco  =  this.new_convert_srting_float(item.preco)
      let valor =  this.new_convert_srting_float(item.valor)
      item.preco = preco
      item.valor = valor
      console.log(item)
      console.log(index)
      this.doPut(item)
    },
    makeToast(variant = null, message = "Error generic") {
      this.counter++;
      this.$bvToast.toast(message, {
        title: `Notificação`,
        toaster: "b-toaster-bottom-right",
        variant: variant,
        solid: true,
        appendToast: true,
      });
    },
    async doPut(venda) {
      //this.onLoader();
      // venda.user_id = this.currentUser.id;
      console.log("monstrando Obejto venda no doput EDIT");
      console.log(venda);
      let response = await http
        .put("/vendaiten/" + venda.id + "?empresa_id=" + this.currentEmpresa.id,venda)
        .catch((error) => {
         // this.offLoader();
          this.makeToast("danger", error.response);
        });
      if (response && response !== null) {
        if (response.status === 200) {
          //this.getData();
          //this.back();
          this.makeToast("success", "Registro alterado");
        }
      }
    },
    confirme_edit_table() {},
    calcular_total() {
      //  console.log(this.outro_valor.frete)
      //  console.log(this.outro_valor.desconto)
      //  console.log(this.outro_valor.adicional)
      let frete = this.new_convert_srting_float(this.outro_valor.frete);
      let desconto = this.new_convert_srting_float(this.outro_valor.desconto);
      let adicional = this.new_convert_srting_float(this.outro_valor.adicional);
      let total = 0;

      console.log("mostrnado Valro Adicional");
      console.log(adicional);
      if (this.select_simbol == 1) {
        total = frete + desconto + adicional;
      } else if (this.select_simbol == 2) {
        total = frete + desconto - adicional;
      }
      this.total_valor = this.formatterCurrBR(total);
      // console.log("Mostrnado o total");
      // console.log(total);
    },
    seleciona_simbolo(event) {
      console.log(event.target.value);
    },
    abr_outros_valores(event) {
      console.log(event.target.value);
      console.log(this.select_outros);
      this.select_outros ? (this.abr_outros = true) : (this.abr_outros = false);
      this.select_outros
        ? (this.sub_tot = "Subtoal")
        : (this.sub_tot = "Total");
    },
    selectiona_name_prod_obj(event, item, index) {
      // console.log(event.target.value);
      // console.log(item);
      // console.log(index);
      console.log("Monstrando v-model");
      console.log(item.select_obj_agrup);

      this.obj_modal_prod.nome = event.target.value;
      this.obj_modal_prod.produto_id = item.id;
      this.obj_modal_prod.index = index;

      if (item.select_obj_agrup) {
        this.vet_guarda_agrupado.push({ ...this.obj_modal_prod });
      } else {
        if (this.vet_guarda_agrupado.length > 0) {
          console.log(this.vet_guarda_agrupado);
          console.log("entour no if maior qque zero");
          console.log(this.obj_modal_prod.produto_id);
          let vet_aux = this.vet_guarda_agrupado.filter(
            (e) => e.produto_id !== this.obj_modal_prod.produto_id
          );

          this.vet_guarda_agrupado = vet_aux;
        }
      }

      console.log("mostrando vetor de agrupados");
      console.log(this.vet_guarda_agrupado);
    },
    selectiona_name_prod(event, item, index) {
      console.log(event.target.value);
      console.log(item);
      console.log(index);
    },
    vendo_new(valor, index) {
      console.log(valor);
      console.log(index);
      this.obj_modal_prod.quantidade = valor;
      this.vet_guarda_agrupado[index].quantidade = valor;

      console.log(this.vet_guarda_agrupado);
    },
    abr_modal_agrup() {
      this.show_modal = true;
      this.vet_guarda_agrupado= []
      this.vet_produtos = []
      this.agrupado_produto_table = false
      this.vet_produtos = null

      setTimeout(()=>{
       
        this.agrupado_produto_table = true
        this.vet_produtos = [...this.produtos]
        this.$forceUpdate();
      },80)
     
    },
    fecha_modal() {
      this.show_modal = false;
    },
    seleciona_produtos(event) {
      console.log("estou na seleiconar Produtos");
      console.log(event);
      let obj_produ = this.vet_produtos.find((p) => p.id == event.target.value);
      this.produto_obj_selecionado = obj_produ;
    },
    del_table(index, objeto) {
      console.log(index);
      console.log(objeto);
      this.items.splice(index, 1);
      this.hidden_button = true;
      this.abr_table = false;
    },
    add_table_confirme(item, index) {
      // console.log("estou add confirme ");
      // console.log(item);
      console.log(index);
      // console.log("Monstrando valores qtd e valor");
      // console.log(item.valor);
      // console.log(item.quantidade);
      // console.log(item.nome);
      let obj_produ = this.vet_produtos.find((p) => p.id == item.nome);
      let objeto = {
        id: 1,
        produto_id:obj_produ.id,
        nome: obj_produ.produto_nome,
        quantidade: item.quantidade,
        valor: item.valor,
        venda_id: this.venda.id,
        preco:
          this.new_convert_srting_float(item.valor) *
          this.new_convert_srting_float(item.quantidade),
      };
      let quantidade = parseInt(objeto.quantidade) 
      let valor = this.new_convert_srting_float(objeto.valor)
      objeto.valor = valor 
      objeto.quantidade = quantidade 
      this.doPost({ ...objeto })
  
    },
    async doPost(venda) {
      venda.empresa_id = this.currentEmpresa.id;
      let response = await http
        .post("/vendaiten?empresa_id=" + this.currentEmpresa.id, venda)
        .catch((error) => {
          this.makeToast("danger", error.response.data);
          // this.offLoader();
        });

      if (response && response.status !== undefined) {
        if (response.status === 200) {
          // this.getData();
          // this.back();
          this.items_table_confirmadas.push({ ...venda });
          this.items = [];
          console.log("mostrando ");
         this.abr_table = false;
          this.hidden_button = true;
          this.makeToast("success", "Registro incluído");
          this.makeToast("success", response.data);
        } else if (response.status == 406) {
          this.makeToast("danger", response.data);
        }
      }
    },
    add_e_fechar_modal(item, index) {
      // console.log("estou add confirme ");
      // console.log(item);
      console.log(index);
      // console.log("Monstrando valores qtd e valor");
      // console.log(item.valor);
      // console.log(item.quantidade);
      // console.log(item.nome);
      // /let obj_produ = this.vet_produtos.find((p) => p.id == item.nome);

      if (this.vet_guarda_agrupado.length > 0) {
        let vet = [...this.vet_guarda_agrupado];
        console.log("ola");
        console.log(vet);
        vet.forEach((e) => {
          let obj = {
            nome: e.nome,
            produto_id:e.produto_id,
            quantidade: e.quantidade,
            valor: 0,
            preco: 0,
            venda_id: this.venda.id
          };
          // this.items_table_confirmadas.push({ ...obj });
          this.doPost({ ...obj })
          this.abr_table = false;
          this.hidden_button = true;
          console.log(e);
        });
      } else {
        let objeto = {
          id: 1,
          produto_id:this.obj_modal_prod.produto_id,
          nome: this.obj_modal_prod.nome,
          quantidade: this.obj_modal_prod.quantidade,
          valor: 0,
          preco: 0,
          venda_id: this.venda.id
        };
        // this.items_table_confirmadas.push({ ...objeto });
        // this.items = [];
        this.doPost({ ...objeto })
        console.log("mostrando");
        this.abr_table = false;
        this.hidden_button = true;
      }
    },
    add_e_continuar_modal(item, index) {
      // console.log("estou add confirme ");
      // console.log(item);
      console.log(index);
      // console.log("Monstrando valores qtd e valor");
      // console.log(item.valor);
      // console.log(item.quantidade);
      // console.log(item.nome);
      // /let obj_produ = this.vet_produtos.find((p) => p.id == item.nome);
      let objeto = {
        id: 1,
        nome: this.obj_modal_prod.nome,
        quantidade: this.obj_modal_prod.quantidade,
        valor: 0,
        preco: 0,
      };
      this.items_table_confirmadas.push({ ...objeto });
      this.items = [];
      console.log("mostrando");
      //this.abr_table = false;
      this.hidden_button = true;
    },
    add_table_plus(item, index) {
      console.log(item);
      console.log(index);
      let obj_produ = this.vet_produtos.find((p) => p.id == item.nome);
      let objeto = {
        id: 1,
        produto_id: obj_produ.id,
        nome: obj_produ.produto_nome,
        quantidade: item.quantidade,
        valor: item.valor,
        preco:
          this.new_convert_srting_float(item.valor) *
          this.new_convert_srting_float(item.quantidade),
        venda_id: this.venda.id
      };
      let valor = this.new_convert_srting_float(objeto.valor)
      objeto.valor = valor
      this.doPost(objeto)
      // this.items_table_confirmadas.push({ ...objeto });
    },
    add_table() {
      let objeto = {
        id: 1,
        nome: null,
        quantidade: null,
        valor: null,
      };

      this.items.push({ ...objeto });
      this.abr_table = true;
      this.hidden_button = false;
      console.log(objeto);
    },
    seleciona_pessoa(objeto) {
      console.log(objeto);
    },
   
    converte_vlr_nfe(){
      this.venda.vlr_nfe = this.new_convert_srting_float(this.venda.vlr_nfe)
    },
    setPut() {
      // this.loader.get = true;
      // setTimeout(function () {
      //   this.loader.get = false;
      // }, 2000);
      console.log("monstrando Obejto venda no put")
      console.log(this.venda)
      this.$emit("doPut", this.venda);
    },
    abr_multiselect(valor) {
      console.log("entrar");
      if (valor == 1) {
        this.show_multiselect = true;
      } else {
        this.show_multiselect = false;
      }
    },
    selecionar_plano(event) {
      this.plano.woner_id = event.id;
    },
    selecionar_tipos(event) {
      this.plano.classificacao = event.id;
    },
    seleciona_orientacoes(event) {
      this.plano.natureza = event.id;
    },
  },
};
</script>

<template>
  <div class="card">
    <div class="card-body">
      <div class="row d-flex justify-content-center align-items-center mb-4">
        <div class="col-md-12 text-center">
          <button class="btn btn-secondary">Visualizar Estoque</button>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <label for=""
            ><span
              :class="
                venda.cliente_id != null && venda.cliente_id != -1
                  ? 'badge badge-success'
                  : 'badge badge-danger'
              "
              >{{ "*Cliente" }}</span
            ></label
          >
          <select
            class="form-control"
            id="exampleFormControlSelect1"
            v-model="venda.cliente_id"
            @change="seleciona_pessoa($event)"
          >
            <option value="-1">selecione um valor</option>
            <option
              v-for="(item, index) in pessoas_vet"
              :key="index"
              :value="item.id"
            >
              {{ item.pes_apelido }}
            </option>
          </select>
        </div>
        <div class="col-md-4">
          <label for=""
            ><span
              :class="
                venda.table_preco_id != null && venda.table_preco_id != -1
                  ? 'badge badge-success'
                  : 'badge badge-danger'
              "
              >{{ "*Tabela de Preço" }}</span
            ></label
          >
          <select
            class="form-control"
            id="exampleFormControlSelect1"
            v-model="venda.table_preco_id"
            @change="seleciona_tab_preco($event)"
          >
            <option value="-1">selecione um valor</option>
            <option
              v-for="(item, index) in vet_tab_precos"
              :key="index"
              :value="item.id"
            >
              {{ item.ttp_nome }}
            </option>
          </select>
        </div>
        <div class="col-md-4">
          <label for=""
            ><span
              :class="
                venda.unidade_id != null && venda.unidade_id != -1
                  ? 'badge badge-success'
                  : 'badge badge-danger'
              "
            >
              {{ "*Unidade Med" }}</span
            ></label
          >
          <select
            class="form-control"
            id="exampleFormControlSelect1"
            v-model="venda.unidade_id"
            @change="seleciona_uni_med($event)"
          >
            <option value="-1">selecione um valor</option>
            <option
              v-for="(item, index) in vet_tipos_unidades"
              :key="index"
              :value="item.id"
            >
              {{ item.nome }}
            </option>
          </select>
        </div>
      </div>
      <div class="row mb-4 mt-4">
        <div class="col-md-4">
          <label for=""
            ><span
              :class="
                venda.vendedor_id != null && venda.vendedor_id != -1
                  ? 'badge badge-success'
                  : 'badge badge-danger'
              "
              >{{ "*Vendedor" }}</span
            >
          </label>
          <select
            class="form-control"
            id="exampleFormControlSelect1"
            v-model="venda.vendedor_id"
            @change="seleciona_vendedor($event)"
          >
            <option value="-1">selecione um valor</option>
            <option
              v-for="(item, index) in pessoas_vet"
              :key="index"
              :value="item.id"
            >
              {{ item.pes_apelido }}
            </option>
          </select>
        </div>
        <div class="col-md-4">
          <label for=""
            ><span
              :class="
                venda.tipo_id != null && venda.tipo_id != -1
                  ? 'badge badge-success'
                  : 'badge badge-danger'
              "
            >
              {{ "*Tipo" }}</span
            ></label
          >
          <select
            class="form-control"
            id="exampleFormControlSelect1"
            v-model="venda.tipo_id"
            @change="seleciona_tipo($event)"
          >
            <option value="-1">selecione um valor</option>
            <option
              v-for="(item, index) in vet_tipos"
              :key="index"
              :value="item.id"
            >
              {{ item.nome }}
            </option>
          </select>
        </div>
        <div class="col-md-4">
          <label for=""
            ><span
              :class="
                venda.situacao_id != null && venda.situacao_id != -1
                  ? 'badge badge-success'
                  : 'badge badge-danger'
              "
              >{{ "*Situação" }}</span
            >
          </label>
          <select
            class="form-control"
            id="exampleFormControlSelect1"
            v-model="venda.situacao_id"
            @change="seleciona_sit($event)"
          >
            <option value="-1">selecione um valor</option>
            <option
              v-for="(item, index) in vet_sit"
              :key="index"
              :value="item.id"
            >
              {{ item.nome }}
            </option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <label for=""
            ><span
              :class="
                venda.data_pedido != null && venda.data_pedido != ''
                  ? 'badge badge-success'
                  : 'badge badge-danger'
              "
              >{{ "*Data pedido" }}</span
            >
          </label>

          <input
            v-model="venda.data_pedido"
            type="datetime-local"
            class="form-control text-left"
          />
        </div>
        <div class="col-md-6">
          <label for=""
            ><span
              :class="
                venda.data_entrega != null && venda.data_entrega != ''
                  ? 'badge badge-success'
                  : 'badge badge-danger'
              "
              >{{ "*Previsão de Entrega" }}</span
            >
          </label>
          <input
            v-model="venda.data_entrega"
            type="datetime-local"
            class="form-control text-left"
          />
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-md-12">
          <div class="card bg-light">
            <div class="card-body">
              <h5 class="card-title">Items:</h5>
              <!-- items_table_confirmadas -->
              <div class="row">
                <div class="col-md-12">
                  <div class="card text-white bg-info">
                    <div class="card-body">
                      <table class="table">
                        <tbody>
                          <tr
                            v-for="(item, index) in items_table_confirmadas"
                            :key="index"
                          >
                            <td
                              style="padding-left: 3rem"
                              v-if="editando_table"
                            >
                              {{ index }}
                            </td>
                            <td
                              style="padding-left: 3rem"
                              v-if="editando_table"
                            >
                              {{ item.nome }}
                            </td>
                            <td
                              style="padding-left: 3rem"
                              v-if="editando_table"
                            >
                              {{ item.quantidade }}
                            </td>
                            <td
                              style="padding-left: 3rem"
                              v-if="editando_table"
                            >
                              {{ item.valor }}
                            </td>
                            <td
                              style="padding-left: 3rem"
                              v-if="editando_table"
                            >
                              {{ formatterCurrBR(item.preco) }}
                            </td>

                            <td
                              style="padding-left: 2rem"
                              v-if="!editando_table && index_edit == index"
                            >
                              {{ index }}
                            </td>
                            <td
                              style="padding-left: 2rem"
                              v-if="!editando_table && index_edit == index"
                            >
                              <input
                                class="form-control text-left"
                                type="text"
                                v-model="item.nome"
                              />
                            </td>
                            <td
                              style="padding-left: 2rem"
                              v-if="!editando_table && index_edit == index"
                            >
                              <input
                                type="number"
                                min="1"
                                class="form-control text-left"
                                v-model="item.quantidade"
                                @input="calculo_edit_table(item, index)"
                              />
                            </td>
                            <td
                              style="padding-left: 3rem"
                              v-if="!editando_table && index_edit == index"
                            >
                              <input
                                @input="
                                  formatInputFloat($event, item, 'valor'),
                                    calculo_edit_table(item, index)
                                "
                                class="form-control text-left"
                                type="text"
                                v-model="item.valor"
                              />
                            </td>
                            <td
                              style="padding-left: 3rem; padding-top: 1.4rem"
                              v-if="!editando_table && index_edit == index"
                            >
                              {{ formatterCurrBR(item.preco) }}
                            </td>

                            <td v-if="editando_table">
                              <button
                                class="btn btn-success ml-4"
                                @click="edit_table(item, index)"
                              >
                                <i class="fas fa-edit"></i>
                              </button>
                              <button
                                class="btn btn-danger ml-2"
                                @click="excluir_table(item, index)"
                              >
                                <i class="fas fa-trash"></i>
                              </button>
                            </td>
                            <td v-if="!editando_table && index_edit == index">
                              <button
                                class="btn btn-success ml-4"
                                @click="update_table_confirmados(item, index)"
                              >
                                <i class="fas fa-check"></i>
                              </button>
                              <button
                                class="btn btn-danger ml-2"
                                @click="edit_table(item, index)"
                              >
                                <i class="fas fa-ban"></i>
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="abr_table">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Nome</th>
                      <th scope="col">Quantiade</th>
                      <th scope="col">Valor</th>
                      <th scope="col">Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in items" :key="index">
                      {{
                        index
                      }}
                      <td>
                        <select
                          class="form-control"
                          id="exampleFormControlSelect1"
                          v-model="item.nome"
                          @change="seleciona_produtos($event)"
                        >
                          <option value="-1">selecione um valor</option>
                          <option
                            v-for="(item, index) in vet_produtos"
                            :key="index"
                            :value="item.id"
                          >
                            {{ item.produto_nome }}
                          </option>
                        </select>
                      </td>
                      <td>
                        <input
                          v-model="item.quantidade"
                          type="number"
                          min="1"
                          class="form-control text-left"
                          placeholder="Digite"
                        />
                      </td>
                      <td>
                        <input
                          @input="formatInputFloat($event, item, 'valor')"
                          v-model="item.valor"
                          type="text"
                          class="form-control text-left"
                          placeholder="Digite"
                        />
                      </td>
                      <td>
                        <span class="badge badge-info mr-2"
                          ><i
                            @click="add_table_plus(item, index)"
                            class="fa fa-plus fa-2x"
                          ></i
                        ></span>
                        <span class="badge badge-success"
                          ><i
                            @click="add_table_confirme(item, index)"
                            class="fa fa-check-circle fa-2x"
                          ></i
                        ></span>
                        <span class="badge badge-danger ml-2 mt-1"
                          ><i
                            @click="del_table(item, index)"
                            class="fa fa-ban fa-2x"
                          ></i
                        ></span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div
                class="row d-flex justify-content-center align-items-center mt-4"
              >
                <!-- sumir junto com a tabela -->
                <div class="col-md-6 text-center" v-if="hidden_button">
                  <button class="btn btn-info" @click="add_table()">
                    Adcionar Produto
                  </button>
                </div>
                <div class="col-md-6 text-center" v-if="hidden_button">
                  <button class="btn btn-info" @click="abr_modal_agrup()">
                    Adcionar Agrupado
                  </button>
                </div>
              </div>
              <div
                class="row d-flex justify-content-right align-items-right mt-4"
              >
                <div class="col-md-6 text-center">
                  <Label>
                    <span class="badge badge-secondary">
                      {{ items_table_confirmadas.length }} {{ "Item" }} ||
                      {{ "Volume" }}
                    </span>
                  </Label>
                </div>
                <div class="col-md-6 text-center">
                  <Label>
                    <span class="badge badge-danger">
                      {{ sub_tot + "1000" }}
                    </span>
                  </Label>
                </div>
              </div>
              <div
                class="row row d-flex justify-content-right align-items-right mt-4"
              >
                <div class="col-md-12 text-center">
                  <input
                    v-model="select_outros"
                    @change="abr_outros_valores($event)"
                    class="form-check-input"
                    type="checkbox"
                    value="1"
                    id="flexCheckDefault"
                  />
                  <label class="form-check-label" for="flexCheckDefault">
                    {{ "Adicionar Outros Valores" }}
                  </label>
                </div>
              </div>
              <div
                class="row row d-flex justify-content-right align-items-right mt-4"
                v-if="abr_outros"
              >
                <div class="col-md-12 text-right">
                  <Label>{{ "frete -> + " }}</Label>
                  <input
                    v-model="outro_valor.frete"
                    @input="
                      formatInputFloat($event, outro_valor, 'frete'),
                        calcular_total()
                    "
                    type="text"
                    class="estilo_input ml-2"
                    style="width: 300px"
                  />
                </div>
              </div>
              <div
                class="row row d-flex justify-content-right align-items-right mt-2"
                v-if="abr_outros"
              >
                <div class="col-md-12 text-right">
                  <Label>{{ "Desconto -> + " }}</Label>
                  <input
                    v-model="outro_valor.desconto"
                    @input="
                      formatInputFloat($event, outro_valor, 'desconto'),
                        calcular_total()
                    "
                    type="text"
                    class="estilo_input ml-2"
                    style="width: 300px"
                  />
                </div>
              </div>
              <div
                class="row row d-flex justify-content-right align-items-right mt-2"
                v-if="abr_outros"
              >
                <div class="col-md-12 text-right">
                  <Label>{{ "Valor Adicional -> " }}</Label>
                  <select
                    class="estilo_input"
                    id="exampleFormControlSelect1"
                    v-model="select_simbol"
                    @change="seleciona_simbolo($event)"
                    style="width: 58px"
                  >
                    <option value="-1">selecione um valor</option>
                    <option
                      v-for="(item, index) in simbolos"
                      :key="index"
                      :value="item.id"
                    >
                      {{ item.nome }}
                    </option>
                  </select>
                  <input
                    v-model="outro_valor.adicional"
                    @input="
                      formatInputFloat($event, outro_valor, 'adicional'),
                        calcular_total()
                    "
                    type="text"
                    class="estilo_input ml-1"
                    style="width: 300px"
                  />
                </div>
              </div>
              <div
                class="row row row d-flex justify-content-right align-items-right mt-2"
                v-if="abr_outros"
              >
                <div class="col-md-12 text-right">
                  <span class="badge badge-danger">{{
                    "Total(R$)" + total_valor
                  }}</span>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <b-modal
                    v-model="show_modal"
                    id="modal-xl"
                    size="xl"
                    title="Cadastro Pedido de Compra"
                    hide-footer
                    @hidden="fecha_modal"
                  >
                    <div class="container">
                      <div
                        class="row d-flex justify-content-center align-items-center " v-if="agrupado_produto_table"
                      >
                        <div
                         
                          class="col-md-3 text-center"
                          v-for="(item, index) in vet_produtos"
                          :key="index"
                      >
                          <div
                            
                            class="card"
                            :class="
                              item.select_obj_agrup ? 'bg-success' : 'bg-light'
                            "
                          >
                            <!-- {{index}} -->
                            <!-- {{item}} -->
                            <div class="card-body">
                              <input
                                v-model="item.select_obj_agrup"
                                @change="
                                  selectiona_name_prod_obj($event, item, index)
                                "
                                class="form-check-input"
                                type="checkbox"
                                :value="item.produto_nome"
                                id="flexCheckDefault"
                              />
                              <label
                                class="form-check-label"
                                for="flexCheckDefault"
                              >
                                {{ item.produto_nome }}
                              </label>
                              <input
                                v-model="item.new_qtd"
                                class="estilo_input"
                                type="number"
                                :disabled="item.select_obj_agrup ? false : true"
                                @input="vendo_new(item.new_qtd, index)"
                              />
                              <span class="ml-1">pct</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="row d-flex justify-content-center align-items-center mt-4"
                      >
                        <div class="col-md-6 text-center">
                          <button
                            class="btn btn-success"
                            @click="fecha_modal(), add_e_fechar_modal()"
                          >
                            Adcionar e Fechar
                          </button>
                        </div>
                        <div class="col-md-6 text-center">
                          <button
                            class="btn btn-info"
                            @click="add_e_continuar_modal()"
                          >
                            Adcionar e continuar
                          </button>
                        </div>
                      </div>
                    </div>
                  </b-modal>
                </div>
              </div>
            </div>
            <!--card -body -->
          </div>
          <!-- card -->
        </div>
        <!-- col -->
      </div>
      <!-- row -->
      <div class="row">
        <div class="col-md-6">
          <div class="card bg-light" style="height: 6.5rem">
            <div class="card-body">
              <h5 class="card-title">Opções:</h5>
              <div class="row d-flex justify-content-center align-items-center">
                <div class="col-mdd-6 text-center">
                  <input
                    @change="selectiona_opcoes($event)"
                    class="form-check-input"
                    type="checkbox"
                    value="item.produto_nome"
                    id="flexCheckDefault"
                    v-model="venda.finaceiro_opc"
                  />
                  <label class="form-check-label mr-4" for="flexCheckDefault">
                    {{ "gerar financeiro" }}
                  </label>
                  <input
                    @change="selectiona_opcoes($event)"
                    class="form-check-input ml-1"
                    type="checkbox"
                    value="item.produto_nome"
                    id="flexCheckDefault"
                    v-model="venda.estoque_opc"
                  />
                  <label class="form-check-label ml-4" for="flexCheckDefault">
                    {{ "Movimentar estoque" }}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="card bg-light">
            <div class="card-body">
              <h5 class="card-title">Empresa:</h5>
              <div class="row">
                <div class="col-md-12">
                  <select
                    class="form-control"
                    id="exampleFormControlSelect1"
                    v-model="venda.select_empresa"
                    @change="seleciona_empresa($event)"
                  >
                    <option value="-1">selecione um valor</option>
                    <option
                      v-for="(item, index) in empresas"
                      :key="index"
                      :value="item.id"
                    >
                      {{ item.nome }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <Label>N Nf-e</Label>
          <input
            v-model="venda.n_nfe"
            type="number"
            class="form-control text-left"
          />
        </div>
        <div class="col-md-4">
          <Label>Data Nf-e</Label>
          <input
            v-model="venda.data_nfe"
            type="datetime-local"
            class="form-control text-left"
          />
        </div>
        <div class="col-md-4">
          <Label>Valor Nf-e</Label>
          <input
            v-model="venda.vlr_nfe"
            @input="formatInputFloat($event, venda, 'vlr_nfe')"
            type="text"
            class="form-control text-left"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <Label>Observações</Label>
          <textarea
            v-model="venda.obs"
            name=""
            id=""
            cols="30"
            rows="10"
            class="form-control text-left"
          ></textarea>
        </div>
      </div>
    </div>
    <div class="card-body">
      <div class="col-md-12 mt-10">
        <button class="btn btn-light" @click.prevent="setPut()">Salvar</button>
        <!-- <i
          v-if="loader.get"
          class="fa fa-spinner fa-4x fa-spin text-success"
        ></i> -->
      </div>
    </div>
  </div>
</template>

<style scoped>
.estilo_input {
  width: 89%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
</style>