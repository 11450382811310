<script>
import Detail from "./detail";
import FaturaVenda from "./faturaVenda.vue"
export default {
  props: {
    currentEmpresa: { type: Object, required: true },
    listLocaisEstoque: { type: Array, required: true },
    pessoas: { type: Array, required: true },
    array_plano: { type: Array, required: true },
    vet_pag_pessoas: { type: Array, required: true },
    pagamentos: { type: Array, required: true },
    hide: { type: Boolean, required: true },
  },
  components: { Detail , FaturaVenda},
  data() {
    return {
      vet_pagamentos:[],
      modal_faturar:false,
      objeto_faturamento:null,
      objeto_cancela:null,
      objeto_recebimento:null,
      modal_recebimento: false,
      vet_pessoas: [],
      titleBody: "Listagem dos Locais de Estoque",
      currentLocalEstoque: undefined,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: false,
      fields: [
        {
          label: "ID",
          key: "id",
          sortable: true,
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Cliente",
          key: "cliente_id",
          sortable: true,
          tdClass: "text-center",
          thClass: "text-center",
        },
        // { label: 'Vendor',  key: 'vendedor_id', sortable: true,tdClass: 'text-center', thClass: 'text-center' },
        {
          label: "N° Nf-e",
          key: "n_nfe",
          sortable: true,
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Valor",
          key: "vlr_nfe",
          sortable: true,
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Dt Nf-e",
          key: "data_nfe",
          sortable: true,
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Situação",
          key: "situacao",
          sortable: true,
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Ações",
          key: "acoes",
          sortable: false,
          tdClass: "text-center",
          thClass: "text-center",
        },
      ],
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.listLocaisEstoque.length ? this.listLocaisEstoque.length : 0;
    },
    clonefoot() {
      return this.listLocaisEstoque.length || this.listLocaisEstoque.length > 10
        ? true
        : false;
    },
    indEstoque() {
      switch (this.localEstoque.ind_est) {
        case 1:
          return "Estoque de propriedade do informante e em posse de terceiros";
        case 2:
          return "Estoque de propriedade de terceiros e em posse do informante";
        default:
          return "Estoque de propriedade do informante e em seu poder";
      }
    },
  },
  created() {
    this.vet_pessoas = this.pessoas;
    this.vet_pagamentos = this.pagamentos
  },
  mounted() {
    this.$emit("newTitle", this.titleBody);
    console.log("Mostrando vet pessoas no List");
    console.log(this.vet_pessoas);
    // console.log("Mostrando tipos de Pagamentos")
  },
  methods: {
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    gerar_boleto(){
       console.log("gerando boleto")
    },
    fatura_venda_update(objeto_venda){
      console.log("estou no updae fatura venda")
      console.log(objeto_venda)
      this.$emit("doPut",objeto_venda)
    },
    fecha_modal_cancela(){
      const modal = document.querySelector("#modal_cancela_venda");
      modal.classList.remove("show");
      modal.style.display = "none";
      modal.setAttribute("aria-hidden", "true");
    },
    cancela_venda(objeto) {
      console.log(objeto);
      this.objeto_cancela  = objeto
      const modal = document.querySelector("#modal_cancela_venda");
      modal.classList.add("show");
      modal.style.display = "block";
      modal.removeAttribute("aria-hidden");
    },
    confirm_cancela(){
       this.objeto_cancela.cancela = 1
       this.$emit('doPut', this.objeto_cancela)
    },
    registra_recebimento(objeto) {
      console.log(objeto);
      this.modal_recebimento = true;
      this.objeto_recebimento  = objeto
      const modal = document.querySelector("#modal_confirmar");
      modal.classList.add("show");
      modal.style.display = "block";
      modal.removeAttribute("aria-hidden");
    },
    registra_fatura(objeto) {
      console.log(objeto);
      let obj_cliente = this.vet_pessoas.find((p)=>p.id == objeto.cliente_id)
      objeto.pessoa = obj_cliente
      this.objeto_faturamento  = objeto
      this.modal_faturar = true
      // const modal = document.querySelector("#modal_fatura");
      // modal.classList.add("show");
      // modal.style.display = "block";
      // modal.removeAttribute("aria-hidden");
    },
    fecha_modal_fatura(){
      this.modal_faturar = false
      // const modal = document.querySelector("#modal_fatura");
      // modal.classList.remove("show");
      // modal.style.display = "none";
      // modal.setAttribute("aria-hidden", "true");
    },
    confirma_recebimento(){
       this.objeto_recebimento.recebido = 1
       this.$emit('doPut', this.objeto_recebimento)
    },
    fecha_modal_recebimento(){
      const modal = document.querySelector("#modal_confirmar");
      modal.classList.remove("show");
      modal.style.display = "none";
      modal.setAttribute("aria-hidden", "true");
    },
    getClienteFantasia(id_cliente) {
      let objeto_pessoa = this.vet_pessoas.find((p) => p.id == id_cliente);
      console.log("mostrando o Objeto pessoa no List ");
      console.log(objeto_pessoa);
      return objeto_pessoa.pes_apelido;
    },
    getVendedor(id_vendedor) {
      let objeto_pessoa = this.vet_pessoas.find((p) => p.id == id_vendedor);
      console.log("mostrando o Objeto pessoa no List ");
      console.log(objeto_pessoa);
      return objeto_pessoa.pes_apelido;
    },
    editar(local) {
      this.$emit("edit", local);
    },
    excluir(local) {
      this.$emit("doDelete", local);
    },
    setLocalEstoque(local) {
      this.currentLocalEstoque = local;
    },
  },
};
</script>

<template>
  <div class="card-body" v-if="hide">
    <div class="row mt-4">
      <!-- Start Limit -->
      <div class="col-sm-12 col-md-6">
        <div id="local-estoque-table_length" class="dataTables_length">
          <label class="d-inline-flex align-items-center">
            Exibir&nbsp;
            <b-form-select
              v-model="perPage"
              size="sm"
              :options="pageOptions"
            ></b-form-select
            >&nbsp;registros
          </label>
        </div>
      </div>
      <!-- End Limit -->
      <!-- Start Search -->
      <div class="col-sm-12 col-md-6">
        <div
          id="local-estoque-table_filter"
          class="dataTables_filter text-md-right"
        >
          <label class="d-inline-flex align-items-center">
            Pesquisar:
            <b-form-input
              v-model="filter"
              type="search"
              placeholder="Pesquisar..."
              class="form-control form-control-sm ml-2"
            ></b-form-input>
          </label>
        </div>
      </div>
      <!-- End search -->
    </div>
    <!-- Start Table -->
    <!--  responsive="sm" -->
    <div class="row mt-4">
      <div v-if="hide" class="col-md-12">
        <b-table
          :items="listLocaisEstoque"
          :fields="fields"
          :per-page="perPage"
          :current-page="currentPage"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
          :hover="true"
          :foot-clone="clonefoot"
        >
          <template #cell(cliente_id)="row">
            {{ getClienteFantasia(row.item.cliente_id) }}
          </template>
          <template #cell(situacao)="row">
            <!-- {{row.item.cancela == 1 ? 'Cancelado' : row.item.recebido == 1 ? 'Recebido' : faturado == 1 ? 'Faturado' : 'Nao Faturando | Não recebido | Não Cancelado '  }} -->
            <span
              :class="
                row.item.cancela == 1
                  ? 'badge badge-danger mr-2'
                  : 'badge badge-danger'
              "
              >{{ row.item.cancela == 1 ? "Cancelado" : "" }}</span
            >
            <span
              :class="
                row.item.recebido == 1
                  ? 'badge badge-success mr-2 '
                  : 'badge badge-danger mr-2'
              "
              >{{ row.item.recebido == 1 ? "Recebido" : "Não Recebido" }}</span
            >
            <span
              :class="
                row.item.faturado == 1
                  ? 'badge badge-success'
                  : 'badge badge-danger'
              "
              >{{ row.item.faturado == 1 ? "Faturado" : "Não Faturado" }}</span
            >
          </template>

          <template #cell(status)="row">
            {{ row.item.status === 1 ? "Sim" : "Não" }}
          </template>
          <template #cell(acoes)="row">
            <!-- `data.value` is the value after formatted by the Formatter -->
            <b-dropdown
              v-bind:id="'dropdown-' + row.item.id"
              class="m-md-2"
              right
              text="Right align"
            >
              <template #button-content>
                <i data-v-6289eca4="" class="bx ri-menu-line"></i>
              </template>
              <b-dropdown-item v-if="row.item.faturado == 0"
                @click="registra_fatura(row.item)"><i class="bx ri-eraser-fill"></i>Faturar</b-dropdown-item
              >
              <b-dropdown-item
                v-b-modal.modal-local-estoque
                @click="setLocalEstoque(row.item)"
                ><i class="bx ri-eraser-fill"></i> Visualizar</b-dropdown-item
              >
              <b-dropdown-item @click="editar(row.item)"
                ><i class="bx ri-edit-2-line"></i> Alterar</b-dropdown-item
              >
              <b-dropdown-item @click="excluir(row.item)"
                ><i class="bx ri-eraser-fill"></i> Excluir</b-dropdown-item
              >
              <b-dropdown-item @click="gerar_boleto(row.item)"
                ><i class="bx ri-eraser-fill"></i> Boleto</b-dropdown-item
              >
              <b-dropdown-item v-if="row.item.recebido == 0" @click="registra_recebimento(row.item)"
                ><i class="bx ri-eraser-fill"></i>Registrar
                Recebimento</b-dropdown-item
              >
              <b-dropdown-item v-if="row.item.cancela == 0" @click="cancela_venda(row.item)"
                ><i class="bx ri-eraser-fill"></i>Cancelar</b-dropdown-item
              >
              <b-dropdown-item
                ><i class="bx ri-eraser-fill"></i>Ficha
                Finaceira</b-dropdown-item
              >
              <b-dropdown-item
                ><i class="bx ri-eraser-fill"></i>Enviar Email</b-dropdown-item
              >
              
             
            </b-dropdown>
          </template>
        </b-table>
      </div>
    </div>
    <!-- End Table -->
    <!-- Start Pagination -->
    <div v-if="hide" class="row mt-4">
      <div class="col">
        <div class="dataTables_paginate paging_simple_numbers float-right">
          <ul class="pagination pagination-rounded mb-0">
            <!-- pagination -->
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
            ></b-pagination>
          </ul>
        </div>
      </div>
    </div>
    <!-- End Pagination -->
    <!-- Start Modal Centro Custo -->
    <b-modal
      v-if="hide"
      hide-footer
      id="modal-local-estoque"
      :title="'Local de Estoque'"
      title-class="font-18"
    >
      <Detail :localEstoque="currentLocalEstoque" />
    </b-modal>
    <!-- Modal Confirmar -->
    <div class="row">
      <div class="col-md-12">
        <div
          id="modal_confirmar"
          class="modal fade"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Confirmar Recebimento</h5>
                <button
                  type="button"
                  class="close"
                  @click="fecha_modal_recebimento()"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
               <div class="card bg-light">
                     <div class="card-body">
                      <h5 class="card-title">Deseja confirmar Recebimento ?</h5>
                     </div>
               </div>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-secondary"
                  @click="fecha_modal_recebimento()"
                >
                  Fechar
                </button>
                <button @click="confirma_recebimento()" type="button" class="btn btn-primary">
                  Confirmar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div
          id="modal_cancela_venda"
          class="modal fade"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Cancelar a Venda</h5>
                <button
                  type="button"
                  class="close"
                  @click="fecha_modal_cancela()"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
               <div class="card bg-light">
                     <div class="card-body">
                      <h5 class="card-title">Deseja Cancelar Venda ?</h5>
                     </div>
               </div>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-secondary"
                  @click="fecha_modal_cancela()"
                >
                  Fechar
                </button>
                <button @click="confirm_cancela()" type="button" class="btn btn-primary">
                  Confirmar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal Faturar  Não usar esse modal aqui , pois ele não tem scroll vertical-->
    <div class="row" v-if="modal_faturar">
         <div class="col-md-12">
          <FaturaVenda @fatura_venda_update="fatura_venda_update" :pagamentos_vet="pagamentos" :vet_pag_pessoas="vet_pag_pessoas" :array_plano="array_plano" :pessoas_vet="vet_pessoas" :objeto_faturamento="objeto_faturamento" :modal_faturar="modal_faturar" @fecha_modal_fatura="fecha_modal_fatura"/>
         </div>
    </div>
    <!-- End Modal Centro Custo -->
  </div>
</template>