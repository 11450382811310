<script>
import appConfig from "@/app.config";
import { http } from "@/helpers/easyindustriaapi/config";
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import List from "./list";
import Insert from "./insert";
import Edit from "./edit";

export default {
  page: {
    title: "Plano de Contas",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader, List, Insert, Edit },
  data() {
    return {
      appName: process.env.VUE_APP_NAME,
      pagamentos:[],
      vet_pag_pessoas:[],
      array_plano:[],
      carrega_funcs: false,
      tab_precos: [],
      produtos: [],
      pessoas: [],
      currentLocalEstoque: {},
      planoConta: [],
      inserindo: false,
      editando: false,
      titleBody: "Vena",
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Cadastro",
          href: "/",
          active: true,
        },
        {
          text: "Venda",
          href: "/parametros/plano",
          active: true,
        },
      ],
    };
  },
  computed: {
    isHide() {
      return !this.inserindo && !this.editando;
    },
  },
  created() {
    this.currentUser = JSON.parse(localStorage.getItem("user"));
    this.currentEmpresa = JSON.parse(localStorage.getItem("currentEmpresa"));
  },
  mounted() {
    this.get_pag_clientes();
    this.get_plano();
    this.get_pessoas();
    this.get_produtos();
    this.get_tipo_pagamentos();
    this.getData();
    
    // this.get_tab_preco();
  },
  methods: {
    makeToast(variant = null, message = "Error generic") {
      this.counter++;
      this.$bvToast.toast(message, {
        title: `Notificação`,
        toaster: "b-toaster-bottom-right",
        variant: variant,
        solid: true,
        appendToast: true,
      });
    },
    changeHomeTitle(newTitle) {
      this.titleBody = newTitle;
    },
    back() {
      this.inserindo = false;
      this.editando = false;
    },
    onLoader() {
      if (document.getElementById("preloader").style.display === "none") {
        document.getElementById("preloader").style.display = "block";
        document.getElementById("status").style.display = "block";
      }
    },
    offLoader() {
      if (document.getElementById("preloader").style.display === "block") {
        setTimeout(function () {
          document.getElementById("preloader").style.display = "none";
          document.getElementById("status").style.display = "none";
        }, 2500);
      }
    },

    async getData() {
      this.onLoader();
      this.carrega_funcs = false;
      try {
        let response = await http.get(
          "/venda?empresa_id=" + this.currentEmpresa.id
        );

        if (response.status === 200) {
          this.planoConta = response.data;
          console.log(this.planoConta);
          this.get_tab_preco();
          this.offLoader();
        }
      } catch (error) {
        let messageErro = error.response.data;

        switch (error.response.status) {
          case 406:
            this.makeToast(
              "danger",
              "Erro 406: " + messageErro.tipo
                ? messageErro.tiponome
                : messageErro.tpag
            );
            this.offLoader();
            break;
          case 404:
            this.makeToast(
              "danger",
              "Erro 404: endpoint não encontrado ou servidor fora do ar"
            );
            this.offLoader();
            break;

          default:
            this.makeToast("danger", error.message);
            this.offLoader();
            break;
        }
      }
    },
    async get_tipo_pagamentos() {
       this.onLoader();
      // this.carrega_funcs = false;
      try {
        let response = await http.get(
          "/tipopagamento?empresa_id=" + this.currentEmpresa.id
        );

        if (response.status === 200) {
          this.pagamentos = response.data;
          console.log(this.pagamentos);
         
        }
      } catch (error) {
        let messageErro = error.response.data;

        switch (error.response.status) {
          case 406:
            this.makeToast(
              "danger",
              "Erro 406: " + messageErro.tipo
                ? messageErro.tiponome
                : messageErro.tpag
            );
            this.offLoader();
            break;
          case 404:
            this.makeToast(
              "danger",
              "Erro 404: endpoint não encontrado ou servidor fora do ar"
            );
            this.offLoader();
            break;

          default:
            this.makeToast("danger", error.message);
            this.offLoader();
            break;
        }
      }
    },
    async get_pag_clientes() {
     // this.onLoader();
      this.carrega_funcs = false;
      try {
        let response = await http.get(
          "/regPagCliente?empresa_id=" + this.currentEmpresa.id
        );

        if (response.status === 200) {
          this.vet_pag_pessoas = response.data;
          console.log(this.vet_pag_pessoas);
          // this.get_tab_preco();
         // this.offLoader();
        }
      } catch (error) {
        let messageErro = error.response.data;

        switch (error.response.status) {
          case 406:
            this.makeToast(
              "danger",
              "Erro 406: " + messageErro.tipo
                ? messageErro.tiponome
                : messageErro.tpag
            );
            this.offLoader();
            break;
          case 404:
            this.makeToast(
              "danger",
              "Erro 404: endpoint não encontrado ou servidor fora do ar"
            );
            this.offLoader();
            break;

          default:
            this.makeToast("danger", error.message);
            this.offLoader();
            break;
        }
      }
    },
    async get_plano() {
      this.onLoader();
      // this.carrega_funcs = false;
      try {
        let response = await http.get(
          "/plano_conta?empresa_id=" + this.currentEmpresa.id
        );

        if (response.status === 200) {
          this.array_plano = response.data;
          console.log(this.array_plano);
         
        }
      } catch (error) {
        let messageErro = error.response.data;

        switch (error.response.status) {
          case 406:
            this.makeToast(
              "danger",
              "Erro 406: " + messageErro.tipo
                ? messageErro.tiponome
                : messageErro.tpag
            );
            this.offLoader();
            break;
          case 404:
            this.makeToast(
              "danger",
              "Erro 404: endpoint não encontrado ou servidor fora do ar"
            );
            this.offLoader();
            break;

          default:
            this.makeToast("danger", error.message);
            this.offLoader();
            break;
        }
      }
    },
    async get_pessoas() {
      this.onLoader();
      try {
        let response = await http.get(
          "/pessoa?empresa_id=" + this.currentEmpresa.id
        );

        if (response.status === 200) {
          this.pessoas = response.data;
          console.log(this.pessoas);
          this.offLoader();
        }
      } catch (error) {
        let messageErro = error.response.data;

        switch (error.response.status) {
          case 406:
            this.makeToast(
              "danger",
              "Erro 406: " + messageErro.tipo
                ? messageErro.tiponome
                : messageErro.tpag
            );
            this.offLoader();
            break;
          case 404:
            this.makeToast(
              "danger",
              "Erro 404: endpoint não encontrado ou servidor fora do ar"
            );
            this.offLoader();
            break;

          default:
            this.makeToast("danger", error.message);
            this.offLoader();
            break;
        }
      }
    },
    async get_produtos() {
      this.onLoader();
      try {
        let response = await http.get(
          "/produto?empresa_id=" + this.currentEmpresa.id
        );

        if (response.status === 200) {
          this.produtos = response.data;
          console.log(this.pessoas);
          this.offLoader();
        }
      } catch (error) {
        let messageErro = error.response.data;

        switch (error.response.status) {
          case 406:
            this.makeToast(
              "danger",
              "Erro 406: " + messageErro.tipo
                ? messageErro.tiponome
                : messageErro.tpag
            );
            this.offLoader();
            break;
          case 404:
            this.makeToast(
              "danger",
              "Erro 404: endpoint não encontrado ou servidor fora do ar"
            );
            this.offLoader();
            break;

          default:
            this.makeToast("danger", error.message);
            this.offLoader();
            break;
        }
      }
    },
    async get_tab_preco() {
      this.onLoader();
      try {
        let response = await http.get(
          "/tipo-tabela-preco?empresa_id=" + this.currentEmpresa.id
        );

        if (response.status === 200) {
          this.tab_precos = response.data;
          console.log(this.tab_precos);
          this.carrega_funcs = true;
          this.offLoader();
        }
      } catch (error) {
        let messageErro = error.response.data;

        switch (error.response.status) {
          case 406:
            this.makeToast(
              "danger",
              "Erro 406: " + messageErro.tipo
                ? messageErro.tiponome
                : messageErro.tpag
            );
            this.offLoader();
            break;
          case 404:
            this.makeToast(
              "danger",
              "Erro 404: endpoint não encontrado ou servidor fora do ar"
            );
            this.offLoader();
            break;

          default:
            this.makeToast("danger", error.message);
            this.offLoader();
            break;
        }
      }
    },

    insert() {
      this.inserindo = !this.inserindo;
    },
    edit(local) {
      console.log(local);
      this.currentLocalEstoque = local;
      this.editando = !this.editando;
    },
    async doPost(venda) {
      venda.empresa_id = this.currentEmpresa.id;
      let response = await http
        .post("/venda?empresa_id=" + this.currentEmpresa.id, venda)
        .catch((error) => {
          this.makeToast("danger", error.response.data);
          this.offLoader();
        });

      if (response && response.status !== undefined) {
        if (response.status === 200) {
          this.getData();
          this.back();
          this.makeToast("success", "Registro incluído");
          this.makeToast("success", response.data);
        } else if (response.status == 406) {
          this.makeToast("danger", response.data);
        }
      }
    },

    async doPut(venda) {
      this.onLoader();
      // venda.user_id = this.currentUser.id;
      console.log("monstrando Obejto venda no doput");
      console.log(venda);
      let response = await http
        .put("/venda/" + venda.id + "?empresa_id=" + this.currentEmpresa.id,venda)
        .catch((error) => {
          this.offLoader();
          this.makeToast("danger", error.response);
        });
      if (response && response !== null) {
        if (response.status === 200) {
          this.getData();
          this.back();
          this.makeToast("success", "Registro alterado");
        }
      }
    },
    async doDelete(plano) {
      this.onLoader();
      plano.empresa_id = this.currentEmpresa.id;
      let response = await http
        .delete(
          "/venda/" + plano.id + "?empresa_id=" + this.currentEmpresa.id,
          plano
        )
        .catch((error) => {
          this.offLoader();
          this.makeToast("danger", error.response);
        });

      if (response.status === 200) {
        this.getData();
        this.back();
        this.makeToast("warning", "Registro excluído");
      }
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="titleBody" :items="items" />
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <!-- Start Bar Buttons -->
          <div class="card-body">
            <div class="row">
              <div class="col-sm-12 col-md-6"></div>
              <div class="col-sm-12 col-md-6 text-md-right">
                <button
                  @click="insert()"
                  v-if="isHide"
                  type="button"
                  class="btn btn-success"
                >
                  + Incluir Venda
                </button>
                <button
                  @click="back()"
                  v-if="!isHide"
                  class="btn btn-secondary"
                >
                  Voltar
                </button>
              </div>
            </div>
          </div>
          <!-- End Bar Buttons -->
          <div class="card-body" v-if="!isHide">
            <Insert
              v-if="inserindo"
              :currentEmpresa="currentEmpresa"
              @newTitle="changeHomeTitle"
              @doPost="doPost"
              :pessoas="pessoas"
              :produtos="produtos"
              :tab_precos="tab_precos"
            />
            <Edit
              v-if="editando"
              :currentEmpresa="currentEmpresa"
              :oldLocalEstoque="currentLocalEstoque"
              @newTitle="changeHomeTitle"
              @doPut="doPut"
              :pessoas="pessoas"
              :produtos="produtos"
              :tab_precos="tab_precos"
            />
          </div>
          <div class="col-md-12 text-center" v-if="!carrega_funcs">
            <i class="fa fa-spinner fa-5x fa-spin text-success"></i>
          </div>
          <div v-if="carrega_funcs == true">
            <List
              v-if="isHide"
              :currentEmpresa="currentEmpresa"
              :listLocaisEstoque="planoConta"
              :hide="isHide"
              :pessoas="pessoas"
              :array_plano="array_plano"
              :vet_pag_pessoas="vet_pag_pessoas"
              :pagamentos="pagamentos"
              @newTitle="changeHomeTitle"
              @edit="edit"
              @doDelete="doDelete"
              @doPut="doPut"
            />
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>